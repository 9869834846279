import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  selectedContentManagerItem: [],
  contentManagerData: [],
  stateContentManagerData: {
    sort: [
      {
        field: "path",
        dir: "asc",
      },
    ],
    filter: {
      logic: "and",
      filters: [{ field: "path", operator: "contains", value: "" }],
    },
  },
  contentManagerPanes: [
    {
      size: "20%",
      min: "20px",
      collapsible: true,
    },
    {},
    {
      size: "30%",
      min: "20px",
      collapsible: true,
    },
  ],
  contentManagerFiles: [],
  contentManagerDataView: "grid",
  uploadContentManagerFiles: false,
  contentManagerDetailsData: [],
};

/**
 * reducer function to handle content manager state changes
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const contentManager = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_SELECTED_CONTENT_ITEM:
      return { ...state, selectedContentManagerItem: payload };

    case ACTION_TYPES.SET_CONTENT_MANAGER_DATA:
      return { ...state, contentManagerData: payload };

    case ACTION_TYPES.SET_STATE_CONTENT_MANAGER_DATA:
      return { ...state, stateContentManagerData: payload };

    case ACTION_TYPES.SET_CONTENT_MANAGER_PANES:
      return { ...state, contentManagerPanes: payload };

    case ACTION_TYPES.SET_CONTENT_MANAGER_FILES:
      return { ...state, contentManagerFiles: payload };

    case ACTION_TYPES.SET_CONTENT_MANAGER_DATA_VIEW:
      return { ...state, contentManagerDataView: payload };

    case ACTION_TYPES.SET_UPLOAD_CONTENT_MANAGER_FILES:
      return { ...state, uploadContentManagerFiles: payload };

    case ACTION_TYPES.SET_CONTENT_MANAGER_DATA_DETAILS:
      return { ...state, contentManagerDetailsData: payload };

    default:
      return state;
  }
};

export default contentManager;
