import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import { updateCurrUser } from "../../../../actions/auth";
import { toggleProjectSidebar } from "../../../../actions/projectSidebar";
import { updateProfile } from "../../../../actions/userProfile";
import { getJobRoles } from "../../../../components/Admin/ManageOrganization/OrganizationFeatures/OrganizationFeaturesContent/TimeBlocks/timeBlocks.service";
import { getCompanyFunctionalArea } from "../../../../components/Admin/admin.service";
import { PROJECT_TYPE } from "../../../../components/ProjectSidebar/projectSidebar.constants";
import { button } from "../../../../config";
import { directory, label, number } from "../../../../config/constants";
import { appendFieldsFromObject, createFormData } from "../../../../helper/formDataHelper";
import { getCompressedBase64 } from "../../../../utils/common";
import LeftProfilePanel from "./LeftProfilePanel";
import RightProfilePanel from "./RightProfilePanel";
import { UserProfileContext } from "./UserProfile.context";
import "./userProfile.scss";

/**
 * UserProfileForm component is responsible for setting up an user profile.
 * parent component: UserProfile
 * @author Bhavana
 */
const UserProfileForm = ({ timezoneNames, managerNames }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const {
    displayName,
    selectedImageFolder,
    profileData,
    manager,
    taskCode,
    jobTitle,
    timeZone,
    selectedImage,
    reminderTime,
    setManagerList,
    setManager,
    setInitialManagerList,
    setTimeZoneList,
    setInitialTimeZone,
    jobRole,
    setJobRole,
    setJobRoleList,
    department,
    officeLocation,
    setDepartmentList,
    setOfficeLocationList,
    setInitialDepartmentList,
    setInitialJobList,
    setInitialOfficeList,
    setLoading,
  } = useContext(UserProfileContext);
  /**
   * call fetchManagerList function when component renders and setTimeZoneList with the list of timezones
   * @author Bhavana
   */
  useEffect(() => {
    (async () => {
      setLoading(true);
      fetchJobRoles();
      await fetchDepartments();
      await fetchOfficeLocations();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    fetchManagerList();
  }, [managerNames]);

  useEffect(() => {
    setTimeZoneList(timezoneNames);
    setInitialTimeZone(timezoneNames);
  }, [timezoneNames]);

  /**
   * Fetches the manager list, sets the manager state, and updates the manager list state.
   * @author Bhavana
   */
  const fetchManagerList = async () => {
    let managerList = managerNames;
    managerList = [{ label: label?.UNASSIGNED, value: null }, ...managerList];
    const managerName = managerList?.find((manager) => manager?.value === user?.Manager);
    setManager(managerName);
    if (managerList) {
      setManagerList(managerList);
      setInitialManagerList(managerList);
    }
  };

  /**
   * fetches the list of Roles
   * @author Sarthak Arora
   */

  const fetchJobRoles = async () => {
    const roles = await getJobRoles(user.companyId);
    let sortedJobRoleList = roles.sort((a, b) => a.RoleName.localeCompare(b.RoleName));
    sortedJobRoleList = [{ JobRoleId: "Unassigned", RoleName: "Unassigned", RoleDescription: "", TimeBlocks: "[]" }, ...roles];
    if (sortedJobRoleList) {
      setJobRoleList(sortedJobRoleList);
      setInitialJobList(sortedJobRoleList);
    }
  };

  /**
   * fetches the list of departments
   * @author Sarthak Arora
   */
  const fetchDepartments = async () => {
    const departmentList = await getCompanyFunctionalArea(number.EIGHT, user.companyId);
    if (departmentList) {
      setDepartmentList(departmentList);
      setInitialDepartmentList(departmentList);
    }
  };
  /**
   * fetches the list of office locations
   * @author Muskan Thakur
   */
  const fetchOfficeLocations = async () => {
    const officeLocationList = await getCompanyFunctionalArea(number.TEN, user.companyId);
    if (officeLocationList) {
      setOfficeLocationList(officeLocationList);
      setInitialOfficeList(officeLocationList);
    }
  };
  /**
   * Updates the user profile setup by creating a payload and call updateProfile api.
   * @author Bhavana
   */
  const updateProfileSetup = async (profileData) => {
    const payloadProfile = {
      ...profileData,
      fileContent: selectedImageFolder ? selectedImageFolder : null,
      userId: user?.id,
    };
    const formData = createFormData();
    appendFieldsFromObject(formData, payloadProfile);
    const response = updateProfile(formData);
    return response;
  };

  /**
   * call updateProfileSetup function to updates the changes and dispatching the updateCurrUser action to update the redux.
   * @author Bhavana
   */
  const saveProfileData = async (e) => {
    e.preventDefault();
    let newProfileData;
    if (profileData.Manager === null) {
      const { Manager, ...updatedProfileData } = profileData;
      newProfileData = updatedProfileData;
    } else newProfileData = profileData;
    const response = await updateProfileSetup(newProfileData);
    dispatch(
      updateCurrUser({
        Name: displayName,
        JobTitle: jobTitle,
        Manager: manager?.value,
        timezone: timeZone,
        UserReminderTime: reminderTime,
        JobRole: jobRole?.JobRoleId,
        Department: department?.EntityId,
        PhotoLink: selectedImage,
        OfficeLocation: officeLocation?.EntityId,
        ...(response && { projectCode: taskCode }),
      })
    );
  };

  /**
   * Hides the project sidebar by dispatching the toggleProjectSidebar action with the PERSONAL_PROJECT type.
   * @author Bhavana
   */
  const hideProjectSidebar = () => {
    dispatch(toggleProjectSidebar(PROJECT_TYPE.PERSONAL_PROJECT, false));
  };

  return (
    <div className='container'>
      <form>
        <div className='row user-profile-content'>
          <LeftProfilePanel />
          <RightProfilePanel />
        </div>
        <div className='text-right mt-2'>
          <button type='button' className='btn btn-secondary mr-2 btn-width' id='userprofileform-hide-project-sidebar' onClick={hideProjectSidebar}>
            {button.CANCEL}
          </button>
          <button type='submit' className='btn btn-primary btn-width' id='userprofileform-save-profile-data' onClick={saveProfileData} disabled={(isEmpty(displayName) || isEmpty(taskCode)) && true}>
            {button.SAVE}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserProfileForm;
