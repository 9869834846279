import { createContext, useContext, useState } from "react";

export const UserProfileContext = createContext({
  timeZoneList: [],
  setTimeZoneList: () => {},
  manager: null,
  setManager: () => {},
  selectedImage: null,
  setSelectedImage: () => {},
  timeZone: null,
  setTimeZone: () => {},
  displayName: null,
  setDisplayName: () => {},
  userEmail: null,
  setUserEmail: () => {},
  jobTitle: null,
  setJobTitle: () => {},
  taskCode: null,
  setTaskCode: () => {},
  reminderTime: null,
  setReminderTime: () => {},
  boardName: null,
  setBoardName: () => {},
  managerList: [],
  setManagerList: () => {},
  initialManagerList: [],
  setInitialManagerList: () => {},
  initialDepartmentList: [],
  setInitialDepartmentList: () => {},
  initialJobList: [],
  setInitialJobList: () => {},
  googleSSOValues: [],
  setGoogleSSOValues: () => {},
  userIdValues: [],
  setUserIdValues: () => {},
  selectedImageFolder: "",
  setSelectedImageFolder: () => {},
  profileData: {},
  setProfileData: () => {},
  initialTimeZone: null,
  setInitialTimeZone: () => {},
  loading: false,
  setLoading: () => {},
});

/**
 * Wrapper component for user profile context provider
 * @autor Bhavana
 */
export const UserProfileContextProvider = ({ children }) => {
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [manager, setManager] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [timeZone, setTimeZone] = useState(null);
  const [initialTimeZone, setInitialTimeZone] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [taskCode, setTaskCode] = useState(null);
  const [reminderTime, setReminderTime] = useState(null);
  const [boardName, setBoardName] = useState(null);
  const [managerList, setManagerList] = useState([]);
  const [initialManagerList, setInitialManagerList] = useState([]);
  const [initialDepartmentList, setInitialDepartmentList] = useState([]);
  const [initialJobList, setInitialJobList] = useState([]);
  const [googleSSOValues, setGoogleSSOValues] = useState([]);
  const [userIdValues, setUserIdValues] = useState([]);
  const [selectedImageFolder, setSelectedImageFolder] = useState("");
  const [profileData, setProfileData] = useState({});
  const [jobRole, setJobRole] = useState("");
  const [department, setDepartment] = useState("");
  const [officeLocation, setOfficeLocation] = useState("");
  const [jobRoleList, setJobRoleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [officeLocationList, setOfficeLocationList] = useState([]);
  const [initialOfficeList, setInitialOfficeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const value = {
    timeZoneList,
    setTimeZoneList,
    manager,
    setManager,
    selectedImage,
    setSelectedImage,
    timeZone,
    setTimeZone,
    displayName,
    setDisplayName,
    userEmail,
    setUserEmail,
    jobTitle,
    setJobTitle,
    taskCode,
    setTaskCode,
    reminderTime,
    setReminderTime,
    boardName,
    setBoardName,
    managerList,
    setManagerList,
    initialManagerList,
    setInitialManagerList,
    initialDepartmentList,
    setInitialDepartmentList,
    initialJobList,
    setInitialJobList,
    googleSSOValues,
    setGoogleSSOValues,
    userIdValues,
    setUserIdValues,
    selectedImageFolder,
    setSelectedImageFolder,
    profileData,
    setProfileData,
    initialTimeZone,
    setInitialTimeZone,
    jobRole,
    setJobRole,
    department,
    setDepartment,
    officeLocation,
    setOfficeLocation,
    jobRoleList,
    setJobRoleList,
    departmentList,
    setDepartmentList,
    officeLocationList,
    setOfficeLocationList,
    initialOfficeList,
    setInitialOfficeList,
    loading,
    setLoading,
  };

  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};

// Custom hook to use the UserProfileContext
export const useProfileContext = () => {
  return useContext(UserProfileContext);
};
