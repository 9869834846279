import * as ACTION_TYPES from "./action-types";

export const setSelectedContentItem = (data) => {
  return {
    type: ACTION_TYPES.SET_SELECTED_CONTENT_ITEM,
    payload: data,
  };
};

export const setContentManagerData = (data) => {
  return {
    type: ACTION_TYPES.SET_CONTENT_MANAGER_DATA,
    payload: data,
  };
};

export const setStateContentManagerData = (data) => {
  return {
    type: ACTION_TYPES.SET_STATE_CONTENT_MANAGER_DATA,
    payload: data,
  };
};

export const setContentManagerPanes = (data) => {
  return {
    type: ACTION_TYPES.SET_CONTENT_MANAGER_PANES,
    payload: data,
  };
};

export const setContentManagerFiles = (data) => {
  return {
    type: ACTION_TYPES.SET_CONTENT_MANAGER_FILES,
    payload: data,
  };
};

export const setContentManagerDataView = (data) => {
  return {
    type: ACTION_TYPES.SET_CONTENT_MANAGER_DATA_VIEW,
    payload: data,
  };
};

export const setUploadContentManagerFiles = (data) => {
  return {
    type: ACTION_TYPES.SET_UPLOAD_CONTENT_MANAGER_FILES,
    payload: data,
  };
};

export const setContentManagerDataDetails = (data) => {
  return {
    type: ACTION_TYPES.SET_CONTENT_MANAGER_DATA_DETAILS,
    payload: data,
  };
};
