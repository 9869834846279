import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { icon, label, number } from "../../../../../config";
import { filterData } from "../../../../../utils/kendo";
import { DrutasDropdown } from "../../../../DrutasDropdown/DrutasDropdown";
import { useKanbanUpdates } from "../../../../KanbanView/hooks/kanban.hooks";
import { handleTaskKeyUpdate } from "../../../../Tasks/tasks.service";
import { existingRelatedAssignedIdListPayload, getDD4List, getInitialRelatedAssignId, getRelatedAssignmentList, getSelectedWorkflowDetails } from "../taskDetail.helper";
import { TaskAssignmentContext } from "./taskAssignment.context";

const TooltipContentTemplate = (props) => {
  return <p>{props.title}</p>;
};

/**
 * TaskAssignee dropdown component
 * Parent component => TaskAssignment
 * @returns JSX
 */
const TaskAssigneeNew = () => {
  const assignmentState = useContext(TaskAssignmentContext);
  const {
    assignmentType,
    assignedId,
    relatedAssignmentId,
    setRelatedAssignmentId,
    relatedAssignmentList,
    setRelatedAssignmentList,
    isDisabled,
    workflowAssignmentType,
    setWorkflowAssignmentType,
    setMatchingFlowElement,
  } = assignmentState;
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);

  const { task, isNewTask, flowElements } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.assignedId);
  const taskWorkflowDetails = useSelector((state) => state.taskWorkflowDetails);

  const { workflowBasicDetails } = taskWorkflowDetails;
  const TaskAssignee = relatedAssignmentId?.RelatedAssignmentType === number.FIVE ? relatedAssignmentId?.label ?? label.UNASSIGNED : relatedAssignmentId?.label ?? label.UNASSIGNED;
  const contentRef = useRef();
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    (async () => {
      if (isNewTask) {
        await handleTaskKeyUpdate(isNewTask, "relatedAssignedId", relatedAssignmentId?.value);
        await handleTaskKeyUpdate(isNewTask, "relatedAssignedType", relatedAssignmentId?.RelatedAssignmentType);
      }
    })();
  }, [isNewTask, relatedAssignmentId]);

  /* sets states for dd4 in case of existing task  */
  useEffect(() => {
    (async () => {
      if (!isNewTask) {
        const relatedAssignedId = await getInitialRelatedAssignId({ task, defaultDetails });
        setRelatedAssignmentId(relatedAssignedId);
        const payload = await existingRelatedAssignedIdListPayload(defaultDetails, task);
        const assignmentList = await getDD4List(payload);
        setRelatedAssignmentList(assignmentList ? assignmentList : []);
      }
    })();
  }, [task.taskId, task?.CurrentRelatedAssignedId, task?.Assignee]);

  /**
   * handles project assignees change, fetches workflow details if task is new and assigned to a workflow
   * sets the matching flow element(Compares tasks DDs with flow elements DDs)
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */

  const handleChange = async (item) => {
    const value = item;
    const relatedType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value === value?.RelatedAssignmentType);
    setMatchingFlowElement(flowElements?.find((obj) => obj.workflowAssignmentId == relatedAssignmentId?.value && obj.taskAssignmentId == assignedId?.value));
    setWorkflowAssignmentType(relatedType);
    setRelatedAssignmentId(value);

    relatedType?.value == number.FIVE && isNewTask && getSelectedWorkflowDetails(null, null, value.value);
  };

  /**
   * Returns OR related assignee list based on related assignment type
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const getAndFilterAssignmentList = async (event) => {
    setFilterText(event?.target?.value ? event?.target?.value : "");
    const payload = { workflowAssignmentType, assignedId, assignmentType, defaultDetails };
    let relatedAssignedIdList = await getDD4List(payload);
    const customFilter = event
      ? {
          logic: "or",
          filters: [
            { field: "Email", operator: "contains", value: event.target.value },
            { field: "label", operator: "contains", value: event.target.value },
          ],
        }
      : null;
    relatedAssignedIdList = event ? filterData(customFilter, relatedAssignedIdList) : relatedAssignedIdList;
    setRelatedAssignmentList(relatedAssignedIdList);
  };

  /**
   * custom dropdown item render for workflow list
   * @returns JSX
   * @author Prachi Jain
   */

  const filtersContent = () => {
    return (
      <div className='p-3 pb-2'>
        <div className='assignto-search position-relative'>
          <span className='assignto-search-icon'>{icon.SEARCH}</span>
          <input autoFocus={true} className='assignto-search-field w-100 p-1' placeholder='Search' value={filterText} onChange={getAndFilterAssignmentList} />
        </div>
      </div>
    );
  };

  const itemRenderNew = (item, handleOnClick) => {
    return (
      <>
        {item?.RelatedAssignmentType === number.TWO ? (
          <div className='d-flex align-items-center drutas-dropdown-popup-element px-3 py-2 truncate-text' onClick={() => handleOnClick(item)}>
            <div className='profile-picture-container mr-3'>{item?.PhotoLink ? <img src={item?.PhotoLink} className='profile-picture' /> : <span>{icon.USER_ICON}</span>}</div>
            <div className='truncate-text'>
              <div className='assignee-name truncate-text'>{item?.label}</div>
              <div className='assignee-email truncate-text'>{item?.Email}</div>
            </div>
          </div>
        ) : (
          <div className='d-flex align-items-center drutas-dropdown-popup-element px-3 py-2 truncate-text' onClick={() => handleOnClick(item)}>
            <div className='profile-picture-container mr-3'>{icon[item?.icon] ? icon[item?.icon] : ""}</div>
            <div className='truncate-text d-flex align-items-center'>
              <div className='assignee-name truncate-text'>{item?.label}</div>
            </div>
          </div>
        )}
      </>
    );
  };

  const valueRenderNew = (item) => {
    return (
      <>
        {item?.RelatedAssignmentType === number.TWO ? (
          <div className='d-flex align-items-center justify-content-between drutas-dropdown-popup-element truncate-text'>
            <div className='d-flex align-items-center'>
              <div className='image-box mr-3'>{!!item?.PhotoLink ? <img src={item?.PhotoLink} /> : <span>{icon.USER_ICON}</span>}</div>
              <div className='truncate-text'>
                <div className='assignee-name truncate-text'>{item?.label}</div>
              </div>
            </div>
            <span>{icon.ARROW_DROP_DOWN_ICON}</span>
          </div>
        ) : (
          <div className='d-flex align-items-center justify-content-between drutas-dropdown-popup-element truncate-text'>
            <div className='d-flex align-items-center truncate-text'>
              <div className='image-box mr-3'>
                <span className='assignee-icon'>{icon[item?.icon] ? icon[item?.icon] : ""}</span>
              </div>
              <div className='truncate-text'>
                <div className='assignee-name truncate-text'>{item?.label}</div>
              </div>
            </div>
            <span>{icon.ARROW_DROP_DOWN_ICON}</span>
          </div>
        )}
      </>
    );
  };

  const clearFilterText = () => {
    getAndFilterAssignmentList();
  };

  return (
    <React.Fragment>
      {workflowAssignmentType?.value && (
        <div className={`form-group col-lg-6 col-md-6 col-sm-6 tour-task-sidebar-taskassignee ${showTaskDetailPanel && !isNewTask ? "mr-0" : "task-assignee-column"}`}>
          {showTaskDetailPanel && !isNewTask ? (
            <>
              <label className='d-flex align-items-center' htmlFor=''>
                {assignedId?.AssignmentType === number.TWO ? label.WORKFLOWS : label.USER_WORKFLOWS}
                {assignedId?.AssignmentType === number.FIVE && (
                  <span aria-label='workflow-info' className='info-icon cursor-pointer' title={workflowBasicDetails?.Description}>
                    {icon.INFO_ICON}
                  </span>
                )}
              </label>
              <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
                <div className='text-truncate' title={TaskAssignee}>
                  {TaskAssignee}
                </div>
              </Tooltip>
            </>
          ) : (
            <div className='d-flex align-items-center assignee-field-wrapper w-100'>
              <div className='assignee-field-inner d-flex'>
                {
                  <div ref={contentRef} className='w-100 assignee-field-box'>
                    <Tooltip content={(props) => <TooltipContentTemplate title={props.title} />} position='bottom' anchorElement='target' parentTitle={true} tooltipClassName='workflow-desc-tooltip'>
                      <label className='d-flex align-items-center' htmlFor=''>
                        {assignedId?.AssignmentType === number.TWO ? label.WORKFLOWS : label.USER_WORKFLOWS}
                        {assignedId?.AssignmentType === number.FIVE && (
                          <span aria-label='workflow-info' className='info-icon cursor-pointer' title={workflowBasicDetails?.Description}>
                            {icon.INFO_ICON}
                          </span>
                        )}
                      </label>
                    </Tooltip>
                    {
                      <div>
                        <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
                          <DrutasDropdown
                            value={relatedAssignmentId ? relatedAssignmentId : null}
                            onChange={handleChange}
                            data={relatedAssignmentList}
                            label={"label"}
                            key={"value"}
                            isfilter={true}
                            filterContent={filtersContent}
                            itemRender={itemRenderNew}
                            valueRender={valueRenderNew}
                            disabled={isDisabled}
                            clearFilterText={clearFilterText}
                          />
                        </Tooltip>
                      </div>
                    }
                    {loader && <div className='preloader loader-wrap'></div>}
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(TaskAssigneeNew);
